import React, { Component } from 'react';

class RelatedProduct extends Component {

    
    render() {
        return (
            <div onClick={() => this.props.handleClick(this.props.prod.item_id)} >
                <img id={"related_" + this.props.prod.item_id} src={process.env.PUBLIC_URL + this.props.prod.images[0].image_file} alt="img" style={{ width: "100%", cursor: "pointer" }} />
            </div>
        );
    }

}

export default RelatedProduct;