const leaflets = {
    "plasticart": {
        "config": {
            "primary-color": "#1d1d4a",
            "secondary-color": "#e25c00",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.2)",
            "clientIcon": require("assets/images/plasticart_clientLogo.png"),
            "serverPath": "plasticart.volantinointerattivo.net/",
            "pdfPath": "/media/interactive_flyer_104/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Plasticart!",
            "shareFlyerURL": "https://plasticart.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Plasticart!",
            "ogTitleMeta": "Volantino Interattivo Plasticart",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://plasticart.volantinointerattivo.net/media/interactive_flyer_104/images/share_facebook.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": true,
            "ga_tracking_id": "UA-152069090-9",
            "release_id": "1",
        },
        "leaflet": {
            "name": "Plasticart",
            "code": "56858",
            "id_campaign": "1234",
            "index": {
                "image_file": "/media/interactive_flyer_104/pages/indice_plasticart_copia.png",
                "links": [{
                        "page": 2,
                        "blueprint": {
                            "top": 24.599764898993357,
                            "left": 6.954188199827732,
                            "width": 81.16973514211887,
                            "height": 4.741038677465476
                        }
                    },
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 34.67728427883832,
                            "left": 7.045542635658912,
                            "width": 81.16973514211887,
                            "height": 4.741038677465476
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 45.27159952431636,
                            "left": 6.862833763996553,
                            "width": 81.16973514211887,
                            "height": 4.741038677465476
                        }
                    }
                ]
            },
            "categories": [{
                    "category_name": "Bomboniere",
                    "subcategories": [
                        "Confetti",
                        "Confezionamento",
                        "Oggettistica"
                    ]
                },
                {
                    "category_name": "Arredo/Casa",
                    "subcategories": [
                        "Casa"
                    ]
                },
                {
                    "category_name": "Horeca",
                    "subcategories": [
                        "Professional"
                    ]
                }
            ],
            "pages": [{
                    "number": 1,
                    "image_file": "/media/interactive_flyer_104/pages/page_1_XGvmcY7.jpg",
                    "products": [{
                        "item_id": "204262",
                        "field1": "confetti ripieni gusti assortiti kg1",
                        "field2": "maxtris",
                        "field3": "",
                        "field4": "",
                        "description": "confetti ripieni gusti assortiti kg1 maxtris",
                        "grammage": null,
                        "price": "10.50",
                        "price_label": "€ 10,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Nut",
                            "Caffè Espresso",
                            "Sfogliatella",
                            "Yogurt",
                            "Pistacchio",
                            "Arachì"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bomboniere",
                        "subcategory": "Confetti",
                        "blueprint": {
                            "top": 53.604932857649686,
                            "left": 0.0,
                            "width": 100.0,
                            "height": 46.395067142350314
                        },
                        "images": [{
                                "image_file": "/media/interactive_flyer_104/products/confetti_maxtris_1580485186.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_104/products/cropped_image_tpmYBKN_9PEIl2c.png",
                                "cropped": true
                            }
                        ],
                        "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Dimmi cosa festeggi",
                                "data": "/media/interactive_flyer_104/video/maxtris.mp4"
                            },
                        ]
                    }, ]
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_104/pages/page_2_PJNyDGv.jpg",
                    "products": [{
                            "item_id": "204230",
                            "field1": "scatola Fleur 5x5 cm  ",
                            "field2": "minnie/mickey rosso",
                            "field3": "disponibile anche fucsia",
                            "field4": "",
                            "description": "scatola Fleur 5x5 cm   minnie/mickey rosso disponibile anche fucsia",
                            "grammage": null,
                            "price": "0.40",
                            "price_label": "€ 0,40",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Minnie",
                                "Mickey Mouse"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Confezionamento",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Plastic_A4_copia_PROVA_pdf_copia_copia_Pagina_2_Immagine__dICqLwS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/topo2_DXWxLsB.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/topo4_F9m3s22.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/topo1_GTEqKlV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/topo3_mXXPpQ9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/topo5_GNyZnBq.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_njUXqxF_OqfJUPl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204486",
                            "field1": "PORTAFOTO MICKEY MOUSE BABY",
                            "field2": "CM.9X12",
                            "field3": "CON SHOPPERS",
                            "field4": "",
                            "description": "PORTAFOTO MICKEY MOUSE BABY CM.9X12 CON SHOPPERS",
                            "grammage": null,
                            "price": "6.50",
                            "price_label": "€ 6,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Oggettistica",
                            "blueprint": {
                                "top": 0.0,
                                "left": 33.527077950043065,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/bomboniera-portafoto-topolino_1580145750.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/portafoto1_aslWwfA.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/portafoto2_exKepga.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_HFrY4lt_yXoHEuF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204581",
                            "field1": "carillon minnie",
                            "field2": "porcellana",
                            "field3": "",
                            "field4": "",
                            "description": "carillon minnie porcellana",
                            "grammage": 0,
                            "price": "11.20",
                            "price_label": "€ 11,20",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Oggettistica",
                            "blueprint": {
                                "top": 0.0,
                                "left": 67.05415590008613,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/cupido-disney-carillon-minnie_1580222953.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_qHg5w8k_1bqvvKj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204261",
                            "field1": "sacchetto cotone",
                            "field2": "stampa calcio",
                            "field3": "h 11cm",
                            "field4": "",
                            "description": "sacchetto cotone stampa calcio h 11cm",
                            "grammage": 0,
                            "price": "0.60",
                            "price_label": "€ 0,60",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Confezionamento",
                            "blueprint": {
                                "top": 30.36175710594315,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Plastic_A4_copia_PROVA_pdf_copia_copia_Pagina_3_Immagine__Dvcleg1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_BqInFVO_eEc6OXB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204260",
                            "field1": "sacchetto cotone con base ",
                            "field2": "stampa pois ",
                            "field3": "con trina decorativa",
                            "field4": "cm 11x12",
                            "description": "sacchetto cotone con base  stampa pois  con trina decorativa cm 11x12",
                            "grammage": 0,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Confezionamento",
                            "blueprint": {
                                "top": 30.297157622739014,
                                "left": 33.527077950043065,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Plastic_A4_copia_PROVA_pdf_copia_copia_Pagina_3_Immagine__fFGmEAW.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_zsbU8fF_YcfFAZy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204527",
                            "field1": "SACCHETTO TONDO COTONE",
                            "field2": "CON SCRITTA LAUREA",
                            "field3": "",
                            "field4": "",
                            "description": "SACCHETTO TONDO COTONE CON SCRITTA LAUREA",
                            "grammage": 0,
                            "price": "0.85",
                            "price_label": "€ 0,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Confezionamento",
                            "blueprint": {
                                "top": 30.297157622739014,
                                "left": 67.05415590008613,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/41155254E410494400000000000000000000000000000000_1580148471.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_vz1XexM_AR9ypal.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204512",
                            "field1": "camion pompieri",
                            "field2": "con clip portafoto",
                            "field3": "",
                            "field4": "",
                            "description": "camion pompieri con clip portafoto",
                            "grammage": 0,
                            "price": "2.00",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Oggettistica",
                            "blueprint": {
                                "top": 53.55297157622739,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/s-l1600_1580147642.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_VfmxLdV_s6vw7gu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204263",
                            "field1": "scatola p.confetti ",
                            "field2": "plexi",
                            "field3": "sagoma albero della vita / tao",
                            "field4": "",
                            "description": "scatola p.confetti  plexi sagoma albero della vita / tao",
                            "grammage": 0,
                            "price": "0.50",
                            "price_label": "€ 0,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Tao",
                                "Albero della vita"
                            ],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Confezionamento",
                            "blueprint": {
                                "top": 53.423772609819125,
                                "left": 33.43572351421189,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Plastic_A4_copia_PROVA_pdf_copia_copia_Pagina_3_Immagine__CATZu6H.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_Y1Rrtct_y2zIQCA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204249",
                            "field1": "mongolfiera metallo",
                            "field2": "colori ass.",
                            "field3": "portaconfetti da appendere  ",
                            "field4": "h11 cm.",
                            "description": "mongolfiera metallo colori ass. portaconfetti da appendere   h11 cm.",
                            "grammage": 0,
                            "price": "2.20",
                            "price_label": "€ 2,20",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Verde",
                                "Argento",
                                "Rosso"
                            ],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Confezionamento",
                            "blueprint": {
                                "top": 53.423772609819125,
                                "left": 67.05415590008613,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Plastic_A4_copia_PROVA_pdf_copia_copia_Pagina_2_Immagine__ikMQ06e.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_SJ2CmQs_ej06xKe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204247",
                            "field1": "culla in metallo                   ",
                            "field2": " p.confetti cm 7  ",
                            "field3": "",
                            "field4": "",
                            "description": "culla in metallo                     p.confetti cm 7  ",
                            "grammage": 0,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Oggettistica",
                            "blueprint": {
                                "top": 76.74418604651163,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Plastic_A4_copia_PROVA_pdf_copia_copia_Pagina_2_Immagine__DhXqjxX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_WH2KzXW_tMCL22I.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204489",
                            "field1": "UNICORNO CERAMICA ",
                            "field2": "DA APPENDERE ",
                            "field3": "h 4,5",
                            "field4": "",
                            "description": "UNICORNO CERAMICA  DA APPENDERE  h 4,5",
                            "grammage": 0,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bomboniere",
                            "subcategory": "Oggettistica",
                            "blueprint": {
                                "top": 76.74418604651163,
                                "left": 33.61843238587424,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/28399_1580146366.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_aXs43Ks_14uQxQA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204537",
                            "field1": "PROFUMATORE PORCELLANA",
                            "field2": "CACTUS",
                            "field3": "D.10",
                            "field4": "",
                            "description": "PROFUMATORE PORCELLANA CACTUS D.10",
                            "grammage": null,
                            "price": "4.00",
                            "price_label": "€ 4,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 76.74418604651163,
                                "left": 67.05415590008613,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Senza_titolo-2_1580149817.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cactus_aLR87I8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_QEbcnZr_MBhoU15.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_104/pages/page_3_nqS1RNO.jpg",
                    "products": [{
                            "item_id": "204538",
                            "field1": "PROFUMATORE BOTTIGLIA",
                            "field2": "C/FIORE GESSO",
                            "field3": "250ml",
                            "field4": "",
                            "description": "PROFUMATORE BOTTIGLIA C/FIORE GESSO 250ml",
                            "grammage": 0,
                            "price": "11.50",
                            "price_label": "€ 11,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/0c47d6153aad0b46e4d7cbe2c2d984bb_1580150499.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_OzZ9H5o_MHAxEUY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204580",
                            "field1": "DECANTER CON MANICO",
                            "field2": "LT. 1 ",
                            "field3": "mod. NUDE",
                            "field4": "",
                            "description": "DECANTER CON MANICO LT. 1  mod. NUDE",
                            "grammage": null,
                            "price": "15.50",
                            "price_label": "€ 15,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 30.29715762273902,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                "image_file": "/media/interactive_flyer_104/products/cropped_image_IdhArU1_bAzayOw.png",
                                "cropped": true
                            }],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204557",
                            "field1": "BICCHIERE V. INALTO ARTE ",
                            "field2": "CL. 48,7",
                            "field3": "D.O.F.",
                            "field4": "",
                            "description": "BICCHIERE V. INALTO ARTE  CL. 48,7 D.O.F.",
                            "grammage": 0,
                            "price": "1.60",
                            "price_label": "€ 1,60",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 53.61757105943152,
                                "left": 33.527077950043065,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/41065740E410451640000000000000000000000000000000_1580207744.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_kKiMKAp_cGSTQ0p.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204539",
                            "field1": "VASO VETRO OBLIQUO",
                            "field2": "h27 d. 23 ",
                            "field3": "effetto optico",
                            "field4": "",
                            "description": "VASO VETRO OBLIQUO h27 d. 23  effetto optico",
                            "grammage": 0,
                            "price": "10.99",
                            "price_label": "€ 10,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 53.55297157622739,
                                "left": 67.05415590008613,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/catalogocvcristal2019_1549008479_Pagina_76_Immagine_0003__zOdyH9Y.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_cUeFJLs_9rFQrfH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204556",
                            "field1": "BICCHIERE V. INALTO ARTE ",
                            "field2": "CL. 38,4",
                            "field3": "ACQUA BASSO",
                            "field4": "",
                            "description": "BICCHIERE V. INALTO ARTE  CL. 38,4 ACQUA BASSO",
                            "grammage": 0,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 76.79614732793392,
                                "left": 33.527077950043065,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/41065740E410452480000000000000000000000000000000.bmp_DOF__MDjue8n.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_5nOtzju_noX8c5x.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204558",
                            "field1": "CALICE INALTO UNO ",
                            "field2": "CL. 47",
                            "field3": "MEDIUM",
                            "field4": "",
                            "description": "CALICE INALTO UNO  CL. 47 MEDIUM",
                            "grammage": 0,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 76.73154784472979,
                                "left": 67.05415590008613,
                                "width": 32.94584409991386,
                                "height": 22.880855256045404
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/41065740E410452600000000000000000000000000000000.bmp_UNO__ZKK0811.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_rigJlrA_0KvDh0C.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204555",
                            "field1": "BOTTIGLIA VETRO",
                            "field2": "OFFICINA",
                            "field3": "9X9X30",
                            "field4": "",
                            "description": "BOTTIGLIA VETRO OFFICINA 9X9X30",
                            "grammage": 0,
                            "price": "5.40",
                            "price_label": "€ 5,40",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 53.604932857649686,
                                "left": 0.0,
                                "width": 32.94584409991386,
                                "height": 46.07206972632964
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/719CxNdpdPL._SL1500__1580207054.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_CbjsIKy_Vr9ucak.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204543",
                            "field1": "CANDELE D'ARREDO",
                            "field2": "MISURE/COLORI ASS.",
                            "field3": "",
                            "field4": "",
                            "description": "CANDELE D'ARREDO MISURE/COLORI ASS.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "€ 0,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 0.0,
                                "left": 33.61843238587424,
                                "width": 66.38156761412576,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/kaarsen_1580202754.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_A2WPDuk_lzqJ4uI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204553",
                            "field1": "POUF CONTENITORE VELVET PIEGHEVOLE",
                            "field2": "38 x 38 x h 38cm",
                            "field3": "",
                            "field4": "",
                            "description": "POUF CONTENITORE VELVET PIEGHEVOLE 38 x 38 x h 38cm",
                            "grammage": null,
                            "price": "16.99",
                            "price_label": "€ 16,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Rosa ",
                                "Grigio"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Arredo/Casa",
                            "subcategory": "Casa",
                            "blueprint": {
                                "top": 30.426356589147286,
                                "left": 33.61843238587424,
                                "width": 66.38156761412576,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Original-833236-2027253-00012518_1580203506.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/coperchiopouf_z8xWvDL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_f8xaQkW_1bOipxo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Pouf Contenitore Velvet",
                                    "data": {
                                        "specifiche": "Pieghevole 38 x 38 x h 38cm, Struttura MDF, Rivestimento in velluto, Dimensioni: 38 x 38 x h 38cm, Disponibile in altri colori",
                                        "img": [
                                            "/media/interactive_flyer_104/images/pouf.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_104/pages/page_4_yudvp9n.jpg",
                    "products": [{
                            "item_id": "204265",
                            "field1": "VASSOIO BAR ANTISCIVOLO NERO",
                            "field2": "CM. 46X35,5",
                            "field3": "",
                            "field4": "",
                            "description": "VASSOIO BAR ANTISCIVOLO NERO CM. 46X35,5",
                            "grammage": 0,
                            "price": "4.50",
                            "price_label": "€ 4,50 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/vassoio-antiscivolo-rotondo-35-cm-blu_1579787004.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_0OleweH_HhQON4R.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204266",
                            "field1": "VASSOIO BAR ANTISCIVOLO NERO",
                            "field2": "CM. 52X38",
                            "field3": "",
                            "field4": "",
                            "description": "VASSOIO BAR ANTISCIVOLO NERO CM. 52X38",
                            "grammage": 0,
                            "price": "5.90",
                            "price_label": "€ 5,90 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 33.527077950043065,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/vassoio-antiscivolo-rotondo-35-cm-blu_1579787267.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_475nMPg_wVbljra.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204570",
                            "field1": "SPREMIAGRUMI E MELOGRANO  ",
                            "field2": "DA BANCO",
                            "field3": "",
                            "field4": "",
                            "description": "SPREMIAGRUMI E MELOGRANO   DA BANCO",
                            "grammage": null,
                            "price": "58.00",
                            "price_label": "€ 58,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 67.14551033591732,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/321428_1580220506.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/spremiagrumi_2Z69aSL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_OnbbPT5_qKu0nD8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_104/pages/page_5_KHdGwiG.jpg",
                    "products": [{
                            "item_id": "204344",
                            "field1": "VETRINA ESPOSITIVA 1PIANO VETRO CURVO",
                            "field2": "CM.55X38X31 ",
                            "field3": "",
                            "field4": "",
                            "description": "VETRINA ESPOSITIVA 1PIANO VETRO CURVO CM.55X38X31 ",
                            "grammage": null,
                            "price": "119.00",
                            "price_label": "€ 119,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/rtn_25-1024x1024_1579863365.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_VH8jrb9_tYT1Zgt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.beckersitaly.net/it/Vetrine-neutre/VETRINA-NEUTRA-RTN-25-p389c71.html"
                                },
                                {
                                    "type": "specs",
                                    "title": "Descrizione",
                                    "data": {
                                        "specifiche": "Codice VET01300, Modello: RTN 25, Capacità: 25 Lt, Peso: 9 Kg, Basamento in acciaio inox, Corpo in vetro, Piano singolo in vetro, Dimensioni: 554x376x305 mm, Dimensioni imballo: 624x457x362 mm, Origine: Asia",
                                        "img": [
                                            "/media/interactive_flyer_104/images/vetrinetta.png"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "204267",
                            "field1": "BICCHIERE V. TUMBLER TIMELESS   ",
                            "field2": "CL.35,5",
                            "field3": "OLD FASHION",
                            "field4": "",
                            "description": "BICCHIERE V. TUMBLER TIMELESS    CL.35,5 OLD FASHION",
                            "grammage": 0,
                            "price": "1.20",
                            "price_label": "€ 1,20 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 33.527077950043065,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/51yJeXC4x7L._AC__1579787691.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_fWzcnZu_FgJuOly.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204264",
                            "field1": "BICCHIERE VETRO. TIMELESS   ",
                            "field2": "CL.45",
                            "field3": "LONG DRINK",
                            "field4": "",
                            "description": "BICCHIERE VETRO. TIMELESS    CL.45 LONG DRINK",
                            "grammage": 0,
                            "price": "1.40",
                            "price_label": "€ 1,40 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 67.14551033591732,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/Timeless_grande_1579786480.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_9vOYz2Z_tzpE8H0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204455",
                            "field1": "VETRINA REFRIGERATA  3 RIP.",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "VETRINA REFRIGERATA  3 RIP.",
                            "grammage": null,
                            "price": "495.00",
                            "price_label": "€ 495,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 30.426356589147286,
                                "left": 0.0,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/41051644E410453580000000000000000000000000000000_1580134808.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_wqwRLyX_eSutmjt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.forcar.it/it/linea-refrigerati/vetrine/espositore-refrigerato-da-banco-con-vetro-curvo/espositore-refrigerato-da-banco-con-vetro-curvo-vpr-120"
                                }
                            ]
                        },
                        {
                            "item_id": "204269",
                            "field1": "BOMBOLETTE PANNA- CREAM",
                            "field2": "PER SIFONE",
                            "field3": "CONF. PZ.10 ",
                            "field4": "",
                            "description": "BOMBOLETTE PANNA- CREAM PER SIFONE CONF. PZ.10 ",
                            "grammage": 0,
                            "price": "4.20",
                            "price_label": "€ 4,20 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 30.36175710594315,
                                "left": 33.527077950043065,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/isi-bombolette-ricarica-per-sifone-panna-8b5_1579788031.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_s3c3Bgo_rH8pVpG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204560",
                            "field1": "SIFONE ELITE  ",
                            "field2": "ALLUMINIO",
                            "field3": "LT. 1",
                            "field4": "",
                            "description": "SIFONE ELITE   ALLUMINIO LT. 1",
                            "grammage": 0,
                            "price": "26.50",
                            "price_label": "€ 26,50 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 0,
                            "max_purchasable_pieces": 0,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 30.36175710594315,
                                "left": 67.14551033591732,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/sifone-panna-alluminio_1580212366.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_RUl5rMI_NjQ6Sof.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        },
                        {
                            "item_id": "204449",
                            "field1": "VETRINA INOX  ",
                            "field2": "50X35X40H",
                            "field3": "A 2 PIANI CALDA",
                            "field4": "",
                            "description": "VETRINA INOX   50X35X40H A 2 PIANI CALDA",
                            "grammage": null,
                            "price": "230.00",
                            "price_label": "€ 230,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 53.61757105943152,
                                "left": 0.0,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/41051644E410455580000000000000000000000000000000_1580127493.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_X9LwNWg_3OOKLeW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "http://horecatech.com/prodotti/vetrine-riscaldate/vetrina-riscaldata-doppia-art-rs553/"
                                }
                            ]
                        },
                        {
                            "item_id": "204561",
                            "field1": "IMPASTATRICE ",
                            "field2": "PLANETARIA",
                            "field3": "LT. 5 ",
                            "field4": "mod.\"HOME\"",
                            "description": "IMPASTATRICE  PLANETARIA LT. 5  mod.\"HOME\"",
                            "grammage": null,
                            "price": "249.00",
                            "price_label": "€ 249,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 53.55297157622739,
                                "left": 33.527077950043065,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/FP105_1580213047.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_Ed63QIF_b4V1ENi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.restoitalia.it/wp-content/themes/Construct/schede-tecniche/Linea%20FP.pdf"
                                }
                            ]
                        },
                        {
                            "item_id": "204562",
                            "field1": "LAMPADA SCALDAVIVANDE NERA",
                            "field2": "estens. DIAM.23 CM.",
                            "field3": "ESTENSIBILE da 50-180 cm",
                            "field4": "d.16",
                            "description": "LAMPADA SCALDAVIVANDE NERA estens. DIAM.23 CM. ESTENSIBILE da 50-180 cm d.16",
                            "grammage": null,
                            "price": "76.00",
                            "price_label": "€ 76,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 53.55297157622739,
                                "left": 67.14551033591732,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/HC_RS614_WEB_1580218146.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/lampada_Do5eAgd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_vCJPlSZ_xmb8zpx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "http://horecatech.com/prodotti/scaldavivande/art-rs614/"
                                }
                            ]
                        },
                        {
                            "item_id": "23235",
                            "field1": "CONTENITORE ISOMETRICO",
                            "field2": "60X40X27H",
                            "field3": "NERO",
                            "field4": "(INT.53,5X34X21)",
                            "description": "CONTENITORE ISOMETRICO 60X40X27H NERO (INT.53,5X34X21)",
                            "grammage": null,
                            "price": "26.00",
                            "price_label": "€ 26,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 76.73154784472979,
                                "left": 0.0,
                                "width": 32.854489664082685,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/contenitore_hwGwZtY.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_x4PUZKy_WhMh74q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "Contenitore isotermico in polipropilene per bacinelle gastronorm GN1/1, capacità 30 litri.",
                                    "data": {
                                        "titolo": "Contenitore isotermico in polipropilene",
                                        "testo": "\nIl contenitore isotermico è ideale per il trasporto dei prodotti alimentari e per la sua capacità di mantenere la temperatura costante.\n\nE' realizzato in polipropilene espanso a cellula chiusa, è privo di sporgenze e spigoli vivi per tutelare la sicurezza degli operatori durante le operazioni di movimentazione e trasporto.\n\nIl polipropilene è un materiale idoneo per il contatto con sostanze alimentari.Espanso con CO2 non cede odori e sapori anomali agli alimenti ed è facilmente lavabile e sanificabile dopo il suo utilizzo.\n\nIl contenitore è resistente, igienico e riciclabile al 100%; compatibile al lavaggio e alla disinfezione industriale con detergenti e disinfettanti, acqua calda e vapore umido fino alla temperatura di 110°C.\n\nPuò contenere una bacinella GN1/1 di altezza 150 mm.",
                                        "img": [
                                            "/media/interactive_flyer_104/images/contenitore.png"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "204565",
                            "field1": "contenitori acciaio inox ",
                            "field2": "per alimenti gastronorm",
                            "field3": "misure ass.",
                            "field4": "",
                            "description": "contenitori acciaio inox  per alimenti gastronorm misure ass.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "€ 0,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": 1,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 76.73154784472979,
                                "left": 33.527077950043065,
                                "width": 66.47292204995694,
                                "height": 22.816255772841263
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/timthumb_1580219674.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/contenitori_NdXaNSj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_vd9pvC2_RIstsV3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                "type": "plus",
                                "title": null,
                                "data": ""
                            }]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_104/pages/page_6_Ka1f0sg.jpg",
                    "products": [{
                            "item_id": "204280",
                            "field1": "ARMADIO REFRIG. INOX ",
                            "field2": "VENT. -0°+10°  ",
                            "field3": " 71X80X205H Lt. 700",
                            "field4": "PERFEKT",
                            "description": "ARMADIO REFRIG. INOX  VENT. -0°+10°    71X80X205H Lt. 700 PERFEKT",
                            "grammage": null,
                            "price": "750.00",
                            "price_label": "€ 750,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 49.48099698535745,
                                "height": 37.09274156095496
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/5788bb8517110_1579793307.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_zo43lHI_Vr8v6B7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://tecnodomspa.com/prodotto/linea-catering/armadi-refrigerati/perfekt-700/"
                                },
                                {
                                    "type": "specs",
                                    "title": "Caratteristiche",
                                    "data": {
                                        "specifiche": "Interno ed esterno in acciaio inossidabile eccetto fondo soffitto e schienale esterni in Acciaio Galvanizzato, Angoli arrotondati interni, Poliuretano ad alta pressione iniettato senza gas CFC pericolosi per l’ambiente, Spessore di isolamento: 60 mm, Porta a chiusura automatica con guarnizione magnetica. Spessore 60 mm. Reversibile con apposito kit (Cod. KITREVPORPK su richiesta), Pannello di controllo elettronico frontale con 1 sonda NTC per modello TN (Temperatura Normale); con 2 sonde NTC per modello BT (Bassa Temperatura), Unità refrigerante a monoblocco estraibile e tropicalizzata di serie (temperatura ambiente +40°C ed umidità relativa del 60%) funzionante con gas R452A, Armadi refrigerati TN e BT (Temperatura Normale e Bassa Temperatura) progettati e predisposti anche per unità di frigorifera remota (SG), Sbrinamento ed evaporazione dell’acqua di condensa completamente automatici, Piedini regolabili in acciaio inossidabile (110/190 H mm), Pronto all’uso.",
                                        "img": [
                                            "/media/interactive_flyer_104/images/armadio.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "204277",
                            "field1": "CANTINETTA VINO ",
                            "field2": "TERMOELET. ",
                            "field3": "12 BOTT.",
                            "field4": "",
                            "description": "CANTINETTA VINO  TERMOELET.  12 BOTT.",
                            "grammage": null,
                            "price": "199.00",
                            "price_label": "€ 199,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 0.0,
                                "left": 50.51900301464255,
                                "width": 49.48099698535745,
                                "height": 37.09274156095496
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/SWS-12T_1579790539.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_Xmxi0VU_mrmXO4A.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.sirman.com/it/catalogo-prodotti/cantinette/soave/Soave-detail"
                                }
                            ]
                        },
                        {
                            "item_id": "204354",
                            "field1": "CANTINETTA VINO 150 LT.",
                            "field2": "DOPPIA TEMP. 5-12/12-22 °C 60X58X85H.",
                            "field3": "",
                            "field4": "",
                            "description": "CANTINETTA VINO 150 LT. DOPPIA TEMP. 5-12/12-22 °C 60X58X85H.",
                            "grammage": null,
                            "price": "590.00",
                            "price_label": "€ 590,00 + IVA",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "0",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Horeca",
                            "subcategory": "Professional",
                            "blueprint": {
                                "top": 37.5968992248062,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 37.09274156095496
                            },
                            "images": [{
                                    "image_file": "/media/interactive_flyer_104/products/CANTINETTE_SOAVE_CE_1579867862.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_104/products/cropped_image_2shp6N7_5dwwPlw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [{
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Cantinetta Vino 150lt",
                                    "data": {
                                        "specifiche": "Capacità: 150 litri, Doppia Temperatura: 5-12/12-22 °C, Dimensioni: 60X58X85H, Porta con vetro temperato doppio, Ripiani estraibili in legno, Comandi digital touch",
                                        "img": [
                                            "/media/interactive_flyer_104/images/cantinetta.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_104/pages/fine_pagina_plasticart_copia.png",
                    "products": []
                }
            ]
        }

    }

}

const lefletSwitch = () => {
    return leaflets.plasticart;
}

export const serverResponse = lefletSwitch();